import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { Global, css } from "@emotion/react";

export const breakpoints = [576, 768, 992, 1200];

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const Layout = ({
  preTitle,
  description,
  image,
  pathname,
  includeStructuredOrg,
  includeStructuredApp,
  children,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            email
            siteUrl
            description
            twitterHandle
          }
          buildTime
          buildTimeYear: buildTime(formatString: "YYYY")
        }
      }
    `
  );
  const { siteMetadata, buildTime, buildTimeYear } = site;

  useEffect(() => {
    firebase.analytics();
    firebase.performance();
  }, []);

  const seo = {
    title: preTitle
      ? `${preTitle} | ${site.siteMetadata.name}`
      : site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: `${site.siteMetadata.siteUrl}${image ? image : `/meta-image.gif`}`,
    url: `${siteMetadata.siteUrl}${pathname || "/"}`,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: seo.title, // The name of the item.
    description: seo.description, // A description of the item.
    url: seo.url, // URL of the item.
    headline: seo.title, // Headline of the article.
    inLanguage: "en",
    copyrightHolder: {
      "@type": "Organization",
      name: siteMetadata.name,
      logo: {
        "@type": "ImageObject",
        url: `${siteMetadata.siteUrl}/logo.png`,
      },
    },
    copyrightYear: buildTimeYear,
    publisher: {
      "@type": "Organization",
      name: siteMetadata.name,
      logo: {
        "@type": "ImageObject",
        url: `${siteMetadata.siteUrl}/logo.png`, // TODO: make this a variable
      },
    },
    datePublished: "2020-06-15T00:00:00+00:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${seo.image}`,
    },
  };

  const schemaOrgOrganization = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: siteMetadata.name,
    url: seo.url, // TODO: should this be the main page or seo.url?
    // Google want a direct url instead of ImageObject: https://developers.google.com/search/docs/data-types/logo#structured-data-type-definitions
    logo: `${siteMetadata.siteUrl}/logo.png`, // TODO: make this a variable
    email: siteMetadata.email,
  };

  const schemaOrgSoftwareApplication = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: siteMetadata.name,
    operatingSystem: "iOS",
    applicationCategory: "BusinessApplication",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      ratingCount: "22",
      bestRating: "5",
    },
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "USD",
    },
    // review // TOOD: add "review"
  };

  return (
    <>
      <Helmet
        title={seo.title}
        // titleTemplate={`%s | ${site.siteMetadata.title}`}
        // defaultTitle={site.siteMetadata.title}
        meta={[
          // BASIC METADATA http://ogp.me/#metadata
          { property: "og:title", content: seo.title },
          { name: "twitter:title", content: seo.title },

          { property: "og:type", content: "website" },

          {
            name: "image",
            content: seo.image,
          },
          {
            property: "og:image",
            content: seo.image,
          },
          {
            name: "twitter:image",
            content: seo.image,
          },

          { property: "og:image:type", content: "image/gif" }, // TODO: fix this isn't always a gif
          { property: "og:image:width", content: "640" }, // best practice is to use 1200 x 630
          { property: "og:image:height", content: "427" },

          { property: "og:url", content: seo.url },

          // OPTIONAL METADATA http://ogp.me/#optional
          {
            name: "description",
            content: seo.description,
          },
          {
            property: "og:description",
            content: seo.description,
          },
          {
            name: "twitter:description",
            content: seo.description,
          },

          {
            property: "og:locale",
            content: "en_US",
          },
          {
            property: "og:site_name",
            content: siteMetadata.name,
          },
          {
            name: "keywords",
            content: "grapic, camera, whiteboard, doodling, pen, paper, remote",
          },

          // TWITTER PARAMETERS
          { name: "twitter:card", content: "summary_large_image" },
          {
            name: "twitter:creator",
            content: siteMetadata.twitterHandle,
          },
          { name: "twitter:site", content: siteMetadata.twitterHandle },
        ]}
      >
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        {includeStructuredOrg && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgOrganization)}
          </script>
        )}
        {includeStructuredApp && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgSoftwareApplication)}
          </script>
        )}
        <html lang="en" />
      </Helmet>
      <Global
        styles={css`
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }

          *:focus:not(:focus-visible) {
            outline: none;
          }

          html {
            box-sizing: border-box;
          }

          body {
            margin: 0;
            font-family: "Open Sans", sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          html,
          body,
          #___gatsby,
          #gatsby-focus-wrapper {
            height: 100%;
          }

          #gatsby-focus-wrapper {
            display: flex;
            flex-direction: column;
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: "Poppins", serif;
            font-weight: bold;
            line-height: 1.1;
            letter-spacing: 1px;
            word-break: break-word;
          }

          code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
              monospace;
          }

          img {
            margin: 0;
          }
        `}
      />
      <>{children}</>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
